

<div class="content">
  <div class="row">
    <div class="card col-md-9">
      <div class="product-list-container">
        <span style="position: absolute; right: 0; bottom: 0; margin-right: 10px;">
          Temp & humidity: <b>{{ sensor[2] }}°C</b>, <b>{{ sensor[1] }}%</b><br><br>
        </span>
        <button (click)="details()" label="" pButton type="button" icon="pi pi-slack" class="p-button-sm"></button>
        <button (click)="logOut()" label="" pButton type="button" icon="pi pi-sign-out" class="p-button-sm"></button>

        <!-- DataView to show products in grid layout -->
        <p-dataView [value]="allProducts" [rows]="4" layout="grid">
          <ng-template let-product pTemplate="gridItem">
            <div class="resizable-container" (click)="panier(product)">
              <div class="media bg-blur rounded m-1">
                <div class="container">
                  <img [src]="product.imageUrl" alt="Avatar" class="image">
                  <div class="overlay" [ngClass]="{'blink': product.expiry < '2023-10-28'}">
                    <div class="text">
                      <div class="textH">
                        <b>{{ product.name }}</b>
                      </div>
                      <br>
                      {{ product.price | currency: 'KRW' }}
                      <br>
                      칸번호: {{ product.row }}{{ product.column }}, 재고: {{ countName(product.name) }}
                      
                    </div>
                  </div>
                  <br>
                  <button (click)="showPopup($event, product)" label="자세히" pButton type="button" class="details-button"></button>
                </div>
                
              </div>
            </div>
          </ng-template>
        </p-dataView>

        <!-- Add these styles in your CSS file or within a <style> tag -->
<style>
  .resizable-container {
    
    width: 200px;
    height:220px; /* Adjust height automatically */
    margin: 0 auto; /* Center the card horizontally */
    
    box-sizing: border-box; /* Include padding in the width calculation */
  }

  .image {
    /*width: 100%;
    height: auto;  Maintain the aspect ratio of the image */
    height: 130px;
    width: 140px;
    max-height: 400px; /* Maximum height for the image */
    object-fit: cover; /* Ensure the image covers the container */
  }

  
</style>

      </div>
    </div>

    <div class="card col-md-3">
      <div class="cart">
        <table class="table text-dark">
          <thead>
            <tr>
              <th class="text-ad">Item</th>
              <!--<th class="text-ad">Qty</th>-->
              <th class="text-ad">Price</th>
              <th class="text-ad">Edit</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngIf="basket.length > 0; else emptyCart">
              <tr *ngFor="let x of basket">
                <td class="text-list">{{ x.name }}</td>
                <!--<td class="text-list">{{ x.quantity }}</td>-->
                <td class="text-list">{{ x.price | currency: 'KRW' }}</td>
                <td>
                  <!--<button class="btn btn-warning btn-lg px-2 py-2 mr-2" style="margin-bottom: 5px;" (click)="reduce(x)">-</button>-->
                  <button class="btn btn-danger btn-lg px-2 py-2 mr-2" (click)="remove(x)">x</button>
                </td>
              </tr>
            </ng-container>
            <ng-template #emptyCart>
              <tr>
                <td colspan="4" class="text-center">No products in the cart.</td>
              </tr>
            </ng-template>
          </tbody>
        </table>
      </div>

      <div class="container m-2">
        <div class="row align-content-center">
          <div class="col-md-6">
            <p class="mt-2 text-dark">{{ cartTotal | currency: 'KRW' }}</p>
          </div>
          <div class="col-md-6 text-right">
            <button class="btn btn-primary btn-block" (click)="checkout()">Buy</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
