<div class="content">

    <div class="row">
    
      <div class="card col-md-9">
            
        <div class="product-list-container">

            <span style="position: absolute; right: 0; bottom: 0; margin-right: 10px;">Temp & humidity : <b>{{ sensor[2] }}°C</b>, <b>{{ sensor[1] }}%</b><br><br></span>
           
              <button (click)="details()" label="" pButton type="button" icon="pi pi-slack" class="p-button-sm"></button>
              <button (click)="logOut()" label="" pButton type="button" icon="pi pi-sign-out" class="p-button-sm"></button>
                   
            <p-dataView [value]="allProducts" [paginator]="false" [rows]="8" layout="grid" paginatorDropdownAppendTo="body">
            
              <ng-template let-product pTemplate="gridItem" class="bg-transparent row">
                  <div class="p-col-12 pl-0 cp" (click)="panier(product)">
                    <div class="media bg-blur rounded m-1">
                      
                      <div class="container">
                        <img [src]="product.imageUrl" alt="Avatar" class="image">
                        
                          <div class="overlay" [ngClass]="{'blink': product.expiry < '2023-10-28'}">
                          <!--<div class="overlay">-->
                          <div class="text">
                            
                            
                            <div class="textH">
                              <!--<b>{{showGroupName(product.group)}}</b>-->
                              <b>{{product.name}}</b>
                            </div>

                            <br>
                            <br>
                            <br>
                            {{ product.price | currency:'KRW' }}
                            <br>
                            <br>
                            <!--만료: {{ product.expiry | date: 'yyyy-MM-dd,' }}<br><br>{{ product.expiry | date: 'h:mm a'}}-->
                            <br>
                            
                            칸번호: {{product.row}}{{product.column}}, 
                            재고: {{countName(product.name)}}
                            
                          </div>

                        </div>
                        <button (click)="showPopup($event,product)" label="자세히" pButton type="button"></button>
                      </div>      

                    </div>
                  </div>
              </ng-template>
            </p-dataView>        
          
        </div>
      </div>
      
      <!--<div *ngIf="showCartSection">-->
      <div class="card col-md-3">
          <div class="cart">
            <table class="table text-dark">
              <thead>
                <tr>
                  
                  <th class="text-ad">Product</th>
                  <th class="text-ad">Qty</th>
                  <th class="text-ad">Price</th>
                  <th class="text-ad">Remove</th>
                  
                </tr>
              </thead>
              <tbody>
                <ng-container *ngIf="basket.length > 0; else emptyCart">
                  <tr *ngFor="let x of basket">
                    <td>{{ x.name }}</td>
                    <td>{{ x.quantity }}</td>
                    <td>{{ x.price  | currency:'KRW' }}</td>
                    <td>
                      <button class="btn btn-warning btn-lg px-3 py-2 mr-2" (click)="reduce(x)">-</button>
                      <button class="btn btn-danger btn-lg px-3 py-2 mr-2" (click)="remove(x)">x</button>
                    </td>
                  </tr>
                </ng-container>
                <ng-template #emptyCart>
                  <tr>
                    <td colspan="4" class="text-center">No products in the cart.</td>
                  </tr>
                </ng-template>
              </tbody>
            </table>
          </div>

          <div class="container m-2">
            <div class="row align-content-center">
              <div class="col-md-6">
                <p class="mt-2 text-dark"> {{ cartTotal | currency:'KRW' }}</p>
              </div>
              <div class="col-md-6 text-right">
                <button class="btn btn-primary btn-block" (click)="checkout()">Buy</button>
              </div>
            </div>
          </div>

        </div>
      
      
    </div>

    
   

  </div>


<!-- shopping cart button with item count
  <button (click)="showCartSection = !showCartSection" class="btn btn-primary position-relative">
<i class="pi pi-shopping-cart"></i> 
<span class="badge position-absolute top-0 start-100 translate-middle rounded-circle"
*ngIf="basket.length > 0">{{ basket.length }}</span>
</button>-->
            
<!--


//paginator divided by each rack tabs on the top before showing all products in one page. Before Github update AUG 30, 2023.

<div class="content">

    <div class="row">
        <div class="card col-md-8">
          
          <p-tabView>
            <p-tabPanel [header]="eachRack.name" [style]="{'font-size': '17px'}" *ngFor="let eachRack of filteredRack">
              
                   
              <span style="position: absolute; right: 0; bottom: 0; margin-right: 10px;">Temp & humidity : <b>{{ sensor[2] }}°C</b>, <b>{{ sensor[0] }}%</b><br><br></span>

              <button (click)="logOut()" label="" pButton type="button" icon="pi pi-sign-out" class="p-button-sm"></button>

              <p-dataView [value]="eachRack.products" [paginator]="eachRack?.products?.length > 9 ? true : false" [rows]="9" layout="grid" paginatorDropdownAppendTo="body">
              
                <ng-template let-product pTemplate="gridItem" class="bg-transparent row">
                    <div class="p-col-12 pl-0 cp" (click)="panier(product)">
                      <div class="media bg-blur rounded m-1">
                        
                        <div class="container">
                          <img [src]="product.imageUrl" alt="Avatar" class="image">
                          <div class="overlay">
                            
                            <div class="text">
                              
                              <b>{{showGroupName(product.group)}}</b>
                              <br>
                              <br>
                              {{ product.price | currency:'KRW' }}
                              <br>
                              <br>
                              만료일: {{product.expiry | date: 'yyyy-MM-dd, h:mm:ss a'}}
                              <br>
                              <br>
                              칸번호: {{product.row}}{{product.column}}, 
                              재고: {{showGroupStock(product.group)}}
                            </div>

                          </div>
                        </div>      

                      </div>
                    </div>
                </ng-template>

              </p-dataView>
                
            </p-tabPanel>
            
          </p-tabView>
          
        </div>
        
        <div class="card col-md-4">
          <div>
            
            <div class="cart">
              <table class="table text-dark">
                <thead>
                  <tr>
                    
                    <th class="text-ad">Product</th>
                    <th class="text-ad">Quantity</th>
                    <th class="text-ad text-center">Price</th>
                    <th class="text-ad">Remove</th>
                    
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let x of basket">
                    
                   
                    <td>{{showGroupName(x.group)}}</td>

                    <td>{{ x.quantity }}</td>
                    <td class="text-center">{{ x.price  | currency:'KRW' }}</td>
                    
                    <td class="text-center">
                      
                   
                      <button class="btn btn-warning btn-lg px-3 py-2 mr-2" (click)="reduce(x)">-</button>
                      <button class="btn btn-danger btn-lg px-3 py-2 mr-2" (click)="remove(x)">X</button>
             
                    </td>
                    

                  </tr>
                  <tr *ngIf="!basket">
                    <td colspan="7">No products found.</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="container m-2">
              <div class="row align-content-center">
                <div class="col-md-6">
                  <p class="mt-2 text-dark"> {{ cartTotal | currency:'KRW' }}</p>
                </div>
                <div class="col-md-6 text-right">
                  <button class="btn btn-primary btn-block" (click)="checkout()">Buy</button>
                </div>
              </div>
            </div>

          </div>
        </div>
      
    </div>
  </div>


-->