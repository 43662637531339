import { Component, OnInit, Input } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { PopupService } from './popup.service';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class PopupComponent implements OnInit {
  
  sharedObject: any = { 
    name: 'Initial name',
    price: 'Initial price',
    expiry: 'Expiry'
   };

  constructor(private dataService: PopupService) {}

  ngOnInit() {
    this.dataService.sharedObject$.subscribe((value) => {
      this.sharedObject = value;
    });
  }
}
