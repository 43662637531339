import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PopupService {
  private sharedObjectSource = new BehaviorSubject<any>({ key: 'Initial Value' });
  sharedObject$ = this.sharedObjectSource.asObservable();

  setSharedObject(value: any) {
    this.sharedObjectSource.next(value);
  }
}
