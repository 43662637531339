  
<div class="col-md-12">
      <div class="card">
        <div class="card-body">

            <div>
                <p [ngClass]="{ 'sign-safe': isProductSafe, 'sign-unsafe': !isProductSafe }">{{ productStatusText }} {{sensor[2]}}  μSv/h</p>
            </div>
            <div #gaugeArea class="guage"></div>

           

            
         
        </div>
      </div>
    </div>

  <div class="col-md-12">

    <div class="card">
      <div class="card-body">
  
        <div class="row">
          <div class="col-md-6 text-start">
            <!--<h2 class="mt-2">Product Types</h2>-->
            <p class="title text-black">Safe Zones</p>
          </div>
          <div class="col-md-6 text-right">
  
            <!--<button (click)="refresh()" pButton type="button" icon="pi pi-refresh" class="p-button-sm p-button-link mr-2"></button>-->
            <button (click)="add()" label="Assign Safe Zone" pButton type="button" icon="pi pi-plus" class="p-button-sm"></button>
  
            <!--<button (click)="details()" label="Details" pButton type="button" icon="pi pi-plus" class="p-button-sm"></button>-->
  
           
          </div>
    </div>
  
    <p-table dataKey="id" stateKey="geiger" [value]="geiger" styleClass="p-datatable-striped" [rows]="2"
      [paginator]="true" #yp1 lang="fr">
       
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="name">
            Name     
          </th>
          
          <th class="text-right" pSortableColumn="created_at">입고반사능률 - μSv/h
          </th>
          <th class="text-right" pSortableColumn="created_at">안전반사능률 - μSv/h
          </th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-geiger>
        <tr>

          <td class="text-left">{{geiger.name}}</td>
          <td class="text-right">{{geiger.entry}}</td>
          <td class="text-right">{{geiger.test}}</td>

          <td class="text-right">
            <!--<button (click)="edit(geiger)" pButton type="button" icon="pi pi-pencil" class="p-button-sm mr-2"></button>-->
            <button (click)="delete(geiger.id)" pButton type="button" icon="pi pi-trash" class="p-button-sm p-button-danger"></button>
          </td>

        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage" class="d-none">
        <tr>
          <td colspan="7">No product types found.</td>
        </tr>
      </ng-template>
    </p-table>
  
    </div>
  </div>
    
  </div>
