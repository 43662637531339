  <div class="text">
      
      
      <br>
      <br>
      <b>이름: {{ sharedObject.name }}</b>
      <br>
      <br>
      <b>가격: {{ sharedObject.price | currency:'KRW' }}</b>
      <br>
      <br>
      <b>만료: {{ sharedObject.expiry | date: 'yyyy-MM-dd,' }}{{ sharedObject.expiry | date: ' h:mm a'}}</b>
      <br>
      <br>
      <b> 안전 반사능률:  </b>
      <span class="badge bg-primary text-white">0.1 μSv/h</span>

      
    </div>
