import { Component, OnInit, ViewChild, ElementRef } from '@angular/core'; // Import ViewChild and ElementRef
import * as GaugeChart from 'gauge-chart'; // Import the gauge-chart library
import { Route, Router } from '@angular/router';
import { AngularFireDatabase } from '@angular/fire/compat/database';

@Component({
  selector: 'app-geiger',
  templateUrl: './geiger.component.html',
  styleUrls: ['./geiger.component.scss'],
})
export class GeigerComponent implements OnInit {
  @ViewChild('gaugeArea', { static: true }) gaugeArea!: ElementRef; // Use ViewChild to get a reference to the gaugeArea element
  isValuePositive: boolean = true;
  isProductSafe: boolean = true;
  value: number = 20;
  productStatusText: string;

  constructor(public router:Router,private db:AngularFireDatabase) {}

  gaugeChart: any; // Define a property to hold the gaugeChart instance
  sensor: any;
  percentage: any;
  wholeData: any=24;

  ngOnInit(): void {
    this.draw(20);
    this.getTest();
  }

  // Properties of the gauge
  gaugeOptions = {
    hasNeedle: true,
    needleColor: 'black',
    arcPadding: '10',
    needleUpdateSpeed: 1000,
    arcColors: ['#558b2f', '#F7DC6F', '#C0392B'],
    //arcColors: ['#558b2f', '#C0392B'], // Use green, yellow, and red colors
    arcDelimiters: [16.66,83.3], // Adjust arcDelimiters as needed
    rangeLabel: ['0', '1'],
    centralLabel: 'μSv/h',
    
    
    //arcPadding: 0.1
  };

  getTest(){
    this.sensor=this.db.list('/serialData').valueChanges().subscribe((res:any) => { 
      this.sensor = res
      if(this.sensor[2]<0.4){
        this.isProductSafe = true;
        this.productStatusText  = 'PRODUCT SAFE: ';
      }else{
        this.isProductSafe = false;
        this.productStatusText  = 'PRODUCT UNSAFE: '
      }
      
      console.log(this.sensor)

      this.percentage = (this.sensor[2] / this.wholeData) * 1000;
      console.log(this.percentage)

      if (this.gaugeArea.nativeElement.firstChild) {
        this.gaugeArea.nativeElement.removeChild(this.gaugeArea.nativeElement.firstChild);
      }

      this.draw(this.percentage)

    });
  }
 
  draw(data: number) {
    GaugeChart.gaugeChart(
      this.gaugeArea.nativeElement,
      800,
      this.gaugeOptions
    ).updateNeedle(data);
  }

  details(){
    this.router.navigate(['pos']);
  }
}
